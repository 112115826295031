<template>
  <div class="w-[63.5rem]">
    <div class="h-full w-full left_section">
      <div class="flex flex-col h-full justify-between py-[1rem] 2xl:py-[2rem] 3xl:py-[3rem]">
        <div class="px-[5rem] mb-4 py-[1rem] 2xl:py-[2rem]">
          <LogoComponent />
        </div>
        <div class="flex-grow flex flex-col justify-center">
          <transition name="slide" mode="out-in">
            <div :key="currentFeature.title" class="px-[5rem]">
            <div v-if="currentFeature.badge" class="flex items-center px-4 w-max py-1 gap-x-2  text-[#0D2859] mb-4 xl:mb-8 2xl:mb-10 bg-white rounded-full">
              <img
                  class="w-5"
                  draggable="false"
                  src="@assets/img/onboarding/crown.svg"
                  alt="crown"
              />
              New Feature
            </div>
            <h2 class="text-left text-[#0D2859] font-bold text-md 2xl:text-3xl">{{ currentFeature.title }}</h2>
            <p class="text-[#0D2859] leading-6 text-md 2xl:text-lg mt-3">{{ currentFeature.description }}</p>
          </div>
          </transition>
          <transition name="slide" mode="out-in">
            <div :key="currentFeature.imageUrl">
              <template v-for="(item) in features" :key="item.imageUrl">
                <img
                   v-show="item.imageUrl === currentFeature.imageUrl && !fetchFeaturesLoader"
                   class="w-full px-10 object-contain transition-opacity duration-300 mt-3 xl:mt-10 2xl:mt-20"
                   :class="{ 'opacity-0': isLoading, 'opacity-100': !isLoading }"
                   draggable="false"
                   :src="item.imageUrl"
                   :alt="item.title"
                   @load="handleImageLoad"
                />
              </template>
              <img
                 v-show="fetchFeaturesLoader"
                 :key="currentFeature.imageUrl"
                 class="w-full px-10 object-contain transition-opacity duration-300 mt-3 xl:mt-10 2xl:mt-20"
                 :class="{ 'opacity-0': isLoading, 'opacity-100': !isLoading }"
                 draggable="false"
                 :src="currentFeature.imageUrl"
                 :alt="currentFeature.title"
                 @load="handleImageLoad"
                />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import LogoComponent from "@modules/account/components/LogoComponent.vue";
import proxy from "@common/lib/http-common";
import {fetchLoginFeaturesURL} from "@src/config/api-utils";
import {ref, onMounted} from "vue";

const features = ref([]);
const isLoading = ref(true);
const currentFeatureIndex = ref(0);
const fetchFeaturesLoader = ref(true);
const currentFeature = ref({
  title: 'AI Powered Tools',
  description: 'Write engaging content, generate images, improve your ideas, and create hashtags, all with AI.',
  imageUrl: 'https://storage.googleapis.com/contentstudio-media-library-nearline/media_library/617918ff8060c50e2367c1b2/uncategorized/original/WHaHC1FlYtDFvP6tQGGhqbPxX5lZNv9dETVp9cSr.png',
  badge: true
})

const handleImageLoad = () => {
  isLoading.value = false;
};

const rotateFeature = () => {
  fetchFeaturesLoader.value = false
  if (features.value.length > 0) {
    isLoading.value = true;
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * features.value.length);
    } while (features.value[nextIndex]?.title === currentFeature.value?.title);

    currentFeatureIndex.value = nextIndex;
    currentFeature.value = features.value[nextIndex];
  }
};

/**
 * Start random features
 */
const startFeatureRotation = () => {
  setInterval(rotateFeature, 5000); // Rotate every 5 seconds
};

/**
 * Fetch login features
 * @returns {Promise<void>}
 */
const getLoginFeatures = async () => {
  try {
    const response = await proxy.get(fetchLoginFeaturesURL)
    if (response?.data?.status) {
      features.value = response?.data?.features || [];

      if (features.value.length > 0) {
        startFeatureRotation();
      }
    }
  } catch (e) {
  }
}

onMounted(() => {
  getLoginFeatures();
});

</script>
<style lang="scss" scoped>
.left_section {
  background: linear-gradient(22deg, #C8E8FF 0%, #EEF4F7 32.18%, #EEF0F7 95.94%);
  box-shadow: 0px 0px 0px 1px #D1E3FB;

  .trusted_section {
    border-radius: 7.24px;
    border-top: 1px solid rgba(255, 255, 255, 0.30);
    background: radial-gradient(314.45% 139.15% at 3.59% 3.24%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.07) 100%);
    backdrop-filter: blur(14.395000457763672px);
  }
}

// Slide transition animations
.slide-enter-active,
.slide-leave-active {
  transition: all 0.8s ease;
}

.slide-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

</style>
